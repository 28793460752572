import { Injectable, effect, signal } from "@angular/core";
import { Subject } from "rxjs";

export type MenuMode =
  | "static"
  | "overlay"
  | "horizontal"
  | "slim"
  | "compact"
  | "reveal"
  | "drawer";

export type ColorScheme = "light" | "dark" | "dim";

export interface AppConfig {
  inputStyle: string;
  colorScheme: ColorScheme;
  theme: string;
  ripple: boolean;
  menuMode: MenuMode;
  scale: number;
  menuTheme: string;
}

interface LayoutState {
  staticMenuDesktopInactive: boolean;
  overlayMenuActive: boolean;
  rightMenuVisible: boolean;
  configSidebarVisible: boolean;
  staticMenuMobileActive: boolean;
  menuHoverActive: boolean;
  searchBarActive: boolean;
  sidebarActive: boolean;
  anchored: boolean;
}

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  _config: AppConfig = {
    ripple: true,
    inputStyle: "outlined",
    menuMode: "reveal",
    colorScheme: "light",
    theme: "blue",
    scale: 16,
    menuTheme: "tactiq",
  };

  state: LayoutState = {
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    rightMenuVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    searchBarActive: false,
    sidebarActive: true,
    anchored: true,
  };

  config = signal<AppConfig>(this._config);

  private configUpdate = new Subject<AppConfig>();

  private overlayOpen = new Subject<unknown>();

  configUpdate$ = this.configUpdate.asObservable();

  overlayOpen$ = this.overlayOpen.asObservable();

  constructor() {
    effect(() => {
      const config = this.config();
      if (this.updateStyle(config)) {
        this.changeTheme();
      }
      this.changeScale(config.scale);
      this.onConfigUpdate();
    });
  }

  updateStyle(config: AppConfig) {
    return (
      config.theme !== this._config.theme ||
      config.colorScheme !== this._config.colorScheme
    );
  }

  changeTheme() {
    const config = this.config();
    const themeLink = document.getElementById("theme-link") as HTMLLinkElement;
    const themeLinkHref = themeLink.getAttribute("href")!;
    const newHref = themeLinkHref
      .split("/")
      .map((el) =>
        el == this._config.theme
          ? (el = config.theme)
          : el == `theme-${this._config.colorScheme}`
            ? (el = `theme-${config.colorScheme}`)
            : el,
      )
      .join("/");

    this.replaceThemeLink(newHref);
  }

  replaceThemeLink(href: string) {
    const id = "theme-link";
    const themeLink = document.getElementById(id) as HTMLLinkElement;
    const cloneLinkElement = themeLink.cloneNode(true) as HTMLLinkElement;

    cloneLinkElement.setAttribute("href", href);
    cloneLinkElement.setAttribute("id", id + "-clone");

    themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);
    cloneLinkElement.addEventListener("load", () => {
      themeLink.remove();
      cloneLinkElement.setAttribute("id", id);
    });
  }

  changeScale(value: number) {
    document.documentElement.style.fontSize = `${value}px`;
  }

  onMenuToggle() {
    if (this.isOverlay()) {
      this.state.overlayMenuActive = !this.state.overlayMenuActive;

      if (this.state.overlayMenuActive) {
        this.overlayOpen.next(null);
      }
    }

    if (this.isDesktop()) {
      this.state.staticMenuDesktopInactive =
        !this.state.staticMenuDesktopInactive;
    } else {
      this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;

      if (this.state.staticMenuMobileActive) {
        this.overlayOpen.next(null);
      }
    }
  }

  onOverlaySubmenuOpen() {
    this.overlayOpen.next(null);
  }

  showRightMenu() {
    this.state.rightMenuVisible = true;
  }

  showConfigSidebar() {
    this.state.configSidebarVisible = true;
  }

  toggleSearchBar() {
    this.state.searchBarActive = !this.state.searchBarActive;
  }

  isOverlay() {
    return this.config().menuMode === "overlay";
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isSlim() {
    return this.config().menuMode === "slim";
  }

  isCompact() {
    return this.config().menuMode === "compact";
  }

  isHorizontal() {
    return this.config().menuMode === "horizontal";
  }

  isMobile() {
    return !this.isDesktop();
  }

  onConfigUpdate() {
    this._config = { ...this.config() };
    this.configUpdate.next(this.config());
  }
}
